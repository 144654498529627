@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.mainpageodinadiv{
    width:100%;
    position: relative;
	height: 100%;


}
.slick-dots {
	position: absolute;
    top: -5%;
    right: 0;
    height: 100%;
    display: flex !important;
    flex-direction: row; /* Yatay hizalama için bu satırı ekleyin */
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  

  .slick-dots li {
	margin: 0 20px 0 0;
	display: flex;
	flex-direction: row;
  }
  .slick-prev, .slick-next {
    background-color: rgba(255, 165, 0, 0.5); /* Düşük opaklıkta turuncu */
    border-radius: 50%; /* Daire şekli */
    width: 65px; /* Buton genişliği */
    height: 65px; /* Buton yüksekliği */
    display: flex;
    align-items: center;
    justify-content: center;
    border: none; /* Kenarlık kaldırıldı */
    transition: background-color 0.3s; /* Geçiş efekti */
	color: red;
	z-index: 2;
}

.slick-prev:hover, .slick-next:hover {
    background-color: rgba(255, 165, 0, 1); /* Opak turuncu */
}
  .slick-dots li button:before {
	font-size: 25px; /* Adjust dot size */
	color: #cccccc; /* Aktif nokta rengi olarak turuncu */

  }

  
  .slick-dots li.slick-active button:before {
	opacity: 1; /* Tam opaklık için */
	color: orange; /* Aktif nokta rengi olarak turuncu */
	text-shadow: 0 0 15px rgba(255, 165, 0, 0.7); /* Turuncu bir glow efekti */
	-webkit-text-fill-color: orange; /* Webkit tarayıcılar için iç dolgu rengi */
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: orange;
  }
  
.odinaimgdiv{
    width:80%;
    height:80vh;
	margin-left: 20%;
    z-index: -1;	
    object-fit: cover;


}

	
.mainoddinaaaaa{
    position: relative;
	height: 80vh;
	
}

.slider-item {
    height: 80vh; /* Slider yüksekliği */
    color: white;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-start;
  }

  .text-overlayod {
	position: absolute;
	right: 250px;
	top: 0;
	width: 40%; /* veya tercih ettiğiniz genişlik */
	height: 100%;
	background-color: transparent;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 11
	;
  }
  .planee{
 	position: absolute;
	right: 40px;
	top: 100px;
  }
  .ship{
	position: absolute;
   right: -65px;
   top: -120px;
 }
 .truck{
	position: absolute;
   right: 5px;
   top: -120px;
 }
  .train{
	position: absolute;
   left: 0px;
   top: -120px;
 }
  /* Yazılar için CSS */
  .text-overlayod h2 {
	font-size: 1.5em;
	display: flex;
justify-content: center;
  line-height: 1.2em;
  font-weight: 800;
  letter-spacing: 4px;
  font-size: 65px;
  color: var(--color-primary);
  }
  
  .text-overlayod p {
	font-size: 1em;
	margin-bottom: 2em;
	justify-content: center;
  line-height: 1.2em;
  font-weight: 400;
  letter-spacing: 3px;
  font-size: 15px;
  color:#35353b;
  width: 89%;
  word-wrap: break-word;
  }
  
  /* Düğme için CSS */
  .text-overlayod button {
	padding: 10px 20px;
	background-color: #000;
	color: white;
	border: none;
	cursor: pointer;
  }
  

  .overlay-imagewhite {
	position: absolute; /* Üst öğeyi konumlandır */
	
	width: 100%; /* Alt öğe genişliğine eşleşecek şekilde ayarla */
	height: 100%; /* .slider-item ile aynı yüksekliğe ayarla */
	z-index: 10; /* Üst öğeyi zemin öğenin üstüne çıkar */
  }

  .odinaimgdiv2{
    width:97vw;
    height:78vh;
    z-index: -1;	

    object-fit: cover;
}
  .mainoddinaaaaa2{
    position: relative;
    height: 78vh; /* Slider yüksekliği */
	width: 97vw;
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 95px;
	margin-bottom: 2%;
	box-shadow: 0 0 20px rgba(0, 0, 0,0.7);
	padding: 0;


}
  .slider-item2 {
    height: 80vh; /* Slider yüksekliği */
    color: white;
	padding: 0;
    display: flex;
    justify-content: flex-start;


  }
  .davxee{
    font-size: 34px;
    color:var(--color-primary);
    font-weight: 300;


  }
  .vurgun {
    position: absolute;
    top: 12%;
    left: 75px;
    z-index: 5;
    width: 22%;
    text-align: right;
    background: rgba(255,255,255,0);

}

.vurgun:before {

    content: '';
    position: absolute;
    width: 70%;  /* Genişlik ayarı */
    height: 75%; /* Yükseklik ayarı */
    top: 60px;       /* Üst pozisyonu ana elemente göre */
    left: 150px;      /* Sol pozisyonu ana elemente göre */
    z-index: -1;  /* Ana elementin altında */
    background: rgba(255,255,255,0.7);

    filter: blur(1px);
}

  .altmeto {
	margin-top: 15px;
    font-size: 17px;
    word-wrap: break-word;
    color: #35353b;
    font-weight: 900;
    filter: brightness(75%)  ;

  }
  .altmeto2 {
	margin-top: 18px;
    font-size: 18px;
	width: 80%;
    word-wrap: break-word;
    color: #35353b;
    font-weight: 900;
    filter: brightness(75%)  ;

  }
  
  .custom-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--color-primary);
    color: white;
    border: 3px solid var(--color-primary);;
    font-size: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-button:hover {
    background-color: transparent;
    color: var(--color-primary);
    border: 3px solid var(--color-primary);;
  }

  .custom-button2 {
    margin-top: 10px;
    margin-bottom: 10px;

    padding: 10px 20px;
    background-color: transparent;
    color: var(--color-primary);
    border: 3px solid var(--color-primary);
    font-size: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-button2:hover {

    background-color: var(--color-primary);
    color: white;
    border: 3px solid var(--color-primary);;
  }
  .custom-button3 {
    margin-top: 10px;
    margin-bottom: 40px;
    width: 100%;
    padding: 10px 10px;
    background-color: transparent;
    color: white;
    border: 3px solid white;
    font-size: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-button3:hover {

    color: var(--color-primary);
    background-color: white;
    border: 3px solid white;

  }
  .secondparsh
  {
    width: 100%;
    background: linear-gradient(to right, #F0F0F0, #F2F2F2, #F5F5F5, #F8F8F8, #FBFBFB);
    height: auto;
    margin-top: 150px;
	position: relative;
  }
.secondalign{
  display: flex;
  justify-content: center;

  
}
.secondgridalign{
  display: grid;
  justify-content: center;

}
.contex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.secondgridalignxx{
  display: grid;
    gap: 15px;
    height: auto;
    width: 80%;
}

.bamboimg{
  width: 451px;
  height: 411px;
  object-fit: cover;
}

.secondparshdar{
}
.mainfonti{
font-size: 34px;
width: 50%;
line-height: 1.2em;
font-family: 400;
word-wrap: break-word;
color: var(--color-primary);
margin-top: 20px;
margin-bottom: 20px;
}
.thirdparsh{
  width: 100%;
  height: auto;
  display: grid;
  justify-content: center;
  padding-bottom: 120px;
}
.fourparsh {
  position: relative;
  background-color: #f2c17d;
  background-image: linear-gradient(315deg, #f5b964 0%, #c05a0c 50%, #d3d3d3 100%);
  
  

  height: auto;
  display: grid;
  justify-content: center;
  margin: 0;
  
}


.innertexth1for{
  line-height: 1.2em;
  font-weight: 400;
  font-size: 30px;
  color: white;
  padding-top: 35px;
}
.innertexth12or{
display: flex;
justify-content: center;
  line-height: 1.2em;
  font-weight: 400;
  font-size: 30px;
  color: var(--color-primary);
  padding-top: 35px;
}

.innertexth12orc{
  display: flex;
  justify-content: center;
    line-height: 1.2em;
    font-weight: 900;
    letter-spacing: 5px;
    font-size: 60px;
    color: var(--color-primary);
    padding-top: 35px;
    margin-bottom: 40px;
    margin-top: 20px;

  }
.mainfontsubi{
  font-size: 18px;
  word-wrap: break-word;
  color: #35353b;
  font-weight: 900;
  filter: brightness(75%)  ;
  }

.mainparsh{
  display: grid;
  justify-content: center;
  text-align: center;

}

.text-content {
  padding-right: 20px; /* Metin ve resim arasında boşluk oluşturur */
  text-align: left;
  width: 30%;
}

.image-content {
}
.contexmain{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.button {
	
	--offset: 10px;
	--border-size: 2px;
	
	display: block;
	position: relative;
	height: 60px;
  width: 250px;
	appearance: none;
	border: 0;
	background: transparent;
	color: #fe6307;
	text-transform: uppercase;
	letter-spacing: .25em;
	outline: none;
	cursor: pointer;
	font-weight: bold;
	border-radius: 0;
	box-shadow: inset 0 0 0 var(--border-size) currentcolor;
	transition: background .8s ease;
  justify-content: center;
  text-align: center;
  vertical-align:middle;
	
	&:hover {
		background: rgba(100, 0, 0, .03);
	}
	
	&__horizontal,
	&__vertical {
		position: absolute;
		top: var(--horizontal-offset, 0);
		right: var(--vertical-offset, 0);
		bottom: var(--horizontal-offset, 0);
		left: var(--vertical-offset, 0);
		transition: transform .8s ease;
		will-change: transform;
		
		&::before {
			content: '';
			position: absolute;
			border: inherit;
		}
	}
	
	&__horizontal {
		--vertical-offset: calc(var(--offset) * -1);
		border-top: var(--border-size) solid currentcolor;
		border-bottom: var(--border-size) solid currentcolor;
		
		&::before {
			top: calc(var(--vertical-offset) - var(--border-size));
			bottom: calc(var(--vertical-offset) - var(--border-size));
			left: calc(var(--vertical-offset) * -1);
			right: calc(var(--vertical-offset) * -1);
		}
	}
	
	&:hover &__horizontal {
		transform: scaleX(0);
	}
	
	&__vertical {
		--horizontal-offset: calc(var(--offset) * -1);
		border-left: var(--border-size) solid currentcolor;
		border-right: var(--border-size) solid currentcolor;
		
		&::before {
			top: calc(var(--horizontal-offset) * -1);
			bottom: calc(var(--horizontal-offset) * -1);
			left: calc(var(--horizontal-offset) - var(--border-size));
			right: calc(var(--horizontal-offset) - var(--border-size));
		}
	}
	
	&:hover &__vertical {
		transform: scaleY(0);
	}
	
}

.topcontanctodina{
  display: flex;
  justify-content: flex-start;

}
.topcontanctodina svg{
  font-size: 24px;
  color: var(--color-primary);
  margin-right: 10px;
}
.topcontanctodina p{

  margin: 0;
  margin-bottom: 5px;
width: 100%;

}
.topcontanctodina p:hover{

  margin: 0;
  margin-bottom: 5px;
  background-color: #fcf3ec; /* Daha saydam bir arka plan */
  border-bottom: 1px solid #c05a0c ;

width: 100%;
}

$contBgClr: linear-gradient(to left bottom, #F2E3C6 0%, #A7A1A5 100%);
$appShadowClr: rgba(#000, .3);

.cont {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-image: $contBgClr;
	overflow: hidden;
}

.app {
 	position: relative;
	min-width: 80%;
	height: 90%;
	box-shadow: 0 0 60px $appShadowClr;
	overflow: hidden;
	
	&__bgimg {
		position: absolute;
		top: 0;
		left: -2.5%;
		width: 105%;
		height: 100%;
		transition: transform 3.5s 770ms;
		
		&-image {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			
			&--1 {
				background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city22222.jpg') center center no-repeat;
				background-size: cover;
			}
			
			&--2 {
				background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/forest.jpg') center center no-repeat;
				background-size: cover;
				opacity: 0;
				transition: opacity 0ms 1300ms;
				will-change: opacity;
			}
		}
	}
	
	&__text {
		position: absolute;
		right: 165px;
		top: 150px;
		font-family: 'Roboto', sans-serif;
		text-transform: uppercase;
		z-index: 1;
		
		&-line {
			transition: transform 1500ms 400ms, opacity 750ms 500ms;
			will-change: transform, opacity;
			user-select: none;
			@for $i from -4 through -1 {
			&--#{abs($i)} {
				transition: transform 1500ms 2000ms + 100ms * ($i - 1), opacity 1500ms 2750ms + 250ms * ($i - 1);
			}
		}
			&--4 {
				font: {
					size: 50px;
					weight: 700;
				}
				color: #0A101D;
			}
			&--3 {
				font: {
					size: 40px;
					weight: 300;
				}
			}
			&--2 {
				margin-top: 10px;
				font: {
					size: 14px;
					weight: 500;
				}
				color: #0099CC;
			}
			&--1 {
				margin-top: 15px;
				img {
					width: 50px;
				}
			}
		}
		
		&--1 {
			
			.app__text-line {
				transform: translate3d(0, -125px, 0);
				opacity: 0;
			}
		}
		
		&--2 {
			right: initial;
			top: 250px;
			left: 80px;
			z-index: -1;
			transition: z-index 1500ms;
			
			@for $i from -4 through -1 {
			.app__text-line--#{abs($i)} {
				opacity: 0;
				transition: transform 1500ms 300ms + 75ms * ($i - 1), opacity 400ms 500ms + 75ms *($i - 1);
			}
		}
		}
	}
	
	&__img {
		position: absolute;
		transform: translate3d(0, -750px, 0);
		width: 850px;
		height: 100%;
		transition: transform 3s cubic-bezier(0.6, 0.13, 0.31, 1.02);
		will-change: transform;
		
		img {
			min-width: 100%;
			user-select: none;
		}
	}
}

.initial {
	.app__img {
		transform: translate3d(0, 0, 0);
	}
	.app__text--1 {
		@for $i from 1 through 4 {
			.app__text-line--#{$i} {
				transform: translate3d(0, 0, 0);
				transition: transform 1500ms 1400ms + 75ms * ($i - 1), opacity 400ms 1600ms + 75ms *($i - 1);
				opacity: 1;
			}
		}
	}
}

.active {
	.app__bgimg {
		transform: translate3d(10px, 0, 0) scale(1.05);
		transition: transform 5s 850ms ease-in-out;
		.app__bgimg-image--2 {
			opacity: 1;
			transition: opacity 0ms 1500ms;
		}
	}
	.app__img {
		transition: transform 3s cubic-bezier(0.6, 0.13, 0.31, 1.02);
		transform: translate3d(0, -1410px, 0);
	}
	.app__text--1 {
		z-index: -1;
		transition: z-index 0ms 1500ms;
		@for $i from 1 through 4 {
			.app__text-line--#{$i} {
				transform: translate3d(0, -125px, 0);
				transition: transform 1500ms 300ms + 75ms * ($i - 1), opacity 400ms 500ms + 75ms *($i - 1);
				opacity: 0;
			}
		}
	}
	.app__text--2 {
		z-index: 1;
		
		@for $i from 1 through 4 {
			.app__text-line--#{$i} {
				transform: translate3d(0, -125px, 0);
				transition: transform 2500ms 1100ms + 75ms * ($i - 1), opacity 1300ms 1300ms + 275ms *($i - 1);
				opacity: 1;
			}
		}
	}
}

.mouse {
	position: absolute;
	right: 20px;
	top: -2px;
	min-width: 50px;
	height: 80px;
	border-radius: 10px;
	z-index: 4;
	border: 5px solid rgba(255, 255, 255, .8);
	&:after {
		content: '';
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #fff;
		animation: scroll 1s infinite alternate;
		@keyframes scroll {
			100% {transform: translate(-50%, 15px)}
		}
	}
}

.pages {
	margin-left: 20px;
	&__list {
		list-style-type: none;
	}
	&__item {
		position: relative;
		margin-bottom: 10px;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 3px solid #fff;
		cursor: pointer;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0, 0);
			width: 75%;
			height: 75%;
			border-radius: 50%;
			background-color: #fff;
			opacity: 0;
			transition: 500ms;
		}
		&:hover:after {
			transform: translate(-50%, -50%) scale(1, 1);
			opacity: 1;
		}
	}
}

.page__item-active {
	&:after {
		transform: translate(-50%, -50%) scale(1, 1);
		opacity: 1;
	}
}

.icon-link {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 50px;
  img {
    width: 100%;
    vertical-align: top;
  }
  &--twitter {
    left: auto;
    right: 5px;
  }
}

.contactholder
{
	width: 100%;
	margin-bottom: 70px;
	padding: 40px;

}

.aboutusholder
{
	display: flex;
	justify-content: flex-start;
	width: 100%;
}
.aboutusimageholder
{
	width: 100%;
	height: 600px;
	object-fit: contain;
}
@media (max-width:1600px)
{

	  /* Yazılar için CSS */
	  .text-overlayod h2 {
		font-size: 1.5em;
		display: flex;
	justify-content: center;
	  line-height: 1.2em;
	  font-weight: 800;
	  letter-spacing: 4px;
	  font-size: 60px;
	  color: var(--color-primary);
	  }
	  
	  .text-overlayod p {
		font-size: 1em;
		margin-bottom: 2em;
		justify-content: center;
	  line-height: 1.2em;
	  font-weight: 400;
	  letter-spacing: 3px;
	  font-size: 15px;
	  color:#35353b;
	  width: 89%;
	  word-wrap: break-word;
	  }
}

  @media (max-width: 1450px) {
	
	.text-overlayod h2 {
		font-size: 1.5em;
		display: flex;
	justify-content: center;
	  line-height: 1.2em;
	  font-weight: 800;
	  letter-spacing: 4px;
	  font-size: 50px;
	  color: var(--color-primary);
	  }
	  
	  .text-overlayod p {
		font-size: 1em;
		margin-bottom: 2em;
		justify-content: center;
	  line-height: 1.2em;
	  font-weight: 400;
	  letter-spacing: 3px;
	  font-weight: 900;
	  font-size: 14px;
	  color:#35353b;
	  width: 89%;
	  word-wrap: break-word;
	  }
    .vurgun {
		position: absolute;
		top: 12%;
		left: 75px;
		z-index: 5;
		width: 35%;
		text-align: right;
		background: rgba(255,255,255,0);
	
	}
	
	.vurgun:before {
	
		content: '';
		position: absolute;
		width: 70%;  /* Genişlik ayarı */
		height: 75%; /* Yükseklik ayarı */
		top: 60px;       /* Üst pozisyonu ana elemente göre */
		left: 150px;      /* Sol pozisyonu ana elemente göre */
		z-index: -1;  /* Ana elementin altında */
		background: rgba(255,255,255,0.7);
	
		filter: blur(1px);
	}
	.altmeto {
		margin-top: 15px;
		font-size: 15px;
		word-wrap: break-word;
		color: #35353b;
		font-weight: 900;
		filter: brightness(75%)  ;
	
	  }
  }
  
@media (max-width:1281px)
{
	.planee{
		position: absolute;
	   right: -20px;
	   top: 100px;
	 }
	 .ship{
	   position: absolute;
	  right: -28px;
	  top: -84px;
	}
	.truck{
	   position: absolute;
	  right: -30px;
	  top: -90px;
	}
	 .train{
	   position: absolute;
	  left: 0px;
	  top: -90px;
	}
}
  @media (max-width: 1250px) {
	.slick-dots {
		position: absolute;
		top: 2%;
		right: 0;
		height: 100%;
		display: flex !important;
		flex-direction: row; /* Yatay hizalama için bu satırı ekleyin */
		justify-content: center;
		list-style: none;
		margin: 0;
		padding: 0;
	  }
	  
	.text-overlayod h2 {
		font-size: 1.5em;
		display: flex;
	justify-content: center;
	  line-height: 1.2em;
	  font-weight: 800;
	  letter-spacing: 4px;
	  font-size: 50px;
	  color: var(--color-primary);
	  }
	  .odinaimgdiv2{
		width:90vw;
		height:80vh;
		z-index: -1;	
	
		object-fit: cover;
	}
	  .slider-item2 {
		height: 80vh; /* Slider yüksekliği */
		color: white;
		padding: 0;
		display: flex;
		justify-content: flex-start;
	
	  }
	  .mainoddinaaaaa2 {
		position: relative;
		height: 80vh;
		width: 98vw;
		margin-left: 10px;
		margin-right: 1%;
		margin-top: 60px;
		margin-bottom: 2%;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
		padding: 0;

	  }
	  .text-overlayod p {
		font-size: 1em;
		margin-bottom: 2em;
		justify-content: center;
	  line-height: 1.2em;
	  letter-spacing: 3px;
	  font-weight: 400;
	  font-size: 14px;
	  color:#35353b;
	  width: 89%;
	  word-wrap: break-word;
	  }
	.vurgun {
		position: absolute;
		top: 8%;
		left: 75px;
		z-index: 5;
		width: 50%;
		text-align: right;
		background: rgba(255,255,255,0);
	
	}
	.davxee
	{
		font-size: 50px;
	}
	.vurgun:before {
	
		content: '';
		position: absolute;
		width: 79%;  /* Genişlik ayarı */
		height: 75%; /* Yükseklik ayarı */
		top: 120px;       /* Üst pozisyonu ana elemente göre */
		left: 120px;      /* Sol pozisyonu ana elemente göre */
		z-index: -1;  /* Ana elementin altında */
		background: rgba(255,255,255,0.3);
	
		filter: blur(1px);
	}
	.altmeto {
		margin-top: 20px;
		font-size: 20px;
		word-wrap: break-word;
		color: #35353b;
		font-weight: 900;
		filter: brightness(75%)  ;
	
	  }
  }
  @media (max-width:1050px) 
  {

	.slick-dots {
		position: absolute;
		top: 95%;
		right: 0;
		height: 100%;
		display: flex !important;
		flex-direction: row; /* Yatay hizalama için bu satırı ekleyin */
		justify-content: center;
		list-style: none;
		margin: 0;
		padding: 0;
	  }
	  .overlay-imagewhite {
    position: absolute;
    width: 140%; /* Genişlik */
    height: 100%; /* Yükseklik */
    z-index: 10;
    object-fit: cover; /* İçeriği kaplayacak şekilde ölçeklendir */
    object-position: center; /* İçeriği üst sol köşeye yasla */
}
	    .text-overlayod {
	position: absolute;
	right: 250px;
	top: 0;
	width: 50%; /* veya tercih ettiğiniz genişlik */
	height: auto;
	background-color: rgba(255,255,255,0.4);

	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 11
	;
  }
  .planee{
 	position: absolute;
	right: 0;
	top: 510px;

  }
  .ship{
	position: absolute;
   right: 0;
   top: 0;

 }
 .truck{
	position: absolute;
   right: 0;
   top: 0;

 }
  .train{
	position: absolute;
   left: 0;
   top: 0;

 }
	.text-overlayod h2 {
		font-size: 1.5em;
		display: flex;
	justify-content: center;
	  line-height: 1.2em;
	  font-weight: 800;
	  letter-spacing: 4px;
	  font-size: 55px;
	  color: var(--color-primary);

	  }
	  .odinaimgdiv2{
		width:100vw;
		height:90vh;
		z-index: -1;	
	
		object-fit: cover;
	}
	  .slider-item2 {
		height: 90vh; /* Slider yüksekliği */
		color: white;
		padding: 0;
		display: flex;
		justify-content: flex-start;
	
	  }
	  .mainoddinaaaaa2 {
		position: relative;
		height: 90vh;
		width: 98vw;
		margin-left: 10px;
		margin-right: 1%;
		margin-top: 60px;
		margin-bottom: 2%;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
		padding: 0;

	  }
	  .text-overlayod p {
	  text-align: left;
		font-size: 1em;
		margin-bottom: 2em;
		justify-content: center;
	  line-height: 1.2em;
	  letter-spacing: 2px;
	  font-size: 24px;
	  color:#35353b;
	  width: 89%;
	  word-wrap: break-word;
	  }
  }
  @media (max-width: 989px) {
	.aboutusholder
{
	display: grid;
	justify-content: center;
	width: 100%;
}
.aboutusimageholder
{
	width: 100%;
	height: auto;
	object-fit: contain;
	margin-top: 20px;
	margin-bottom:20px
}
   .text-overlayod {
	position: absolute;
	right: 250px;
	top: 0;
	width: 70%; /* veya tercih ettiğiniz genişlik */
	height: 100%;
	background-color: transparent;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 11
	;
  }
.planee{
 	position: absolute;
	right: -70px;
	top: 310px;
	background-color: rgba(255,255,255,0.4);
  }
  .ship{
	position: absolute;
   right: -70px;
   top: 15px;
   height: 40%;
   background-color: rgba(255,255,255,0.4);
   
 }
 .truck{
	position: absolute;
   right: -60px;
   top: 60px;
     height: 40%;
   background-color: rgba(255,255,255,0.4);

 }
  .train{
	position: absolute;
   left: 0px;
   top: 60px;
        height: 40%;
   background-color: rgba(255,255,255,0.4);
 }
	.text-overlayod h2 {
		font-size: 1.5em;
		display: flex;
	justify-content: center;
	  line-height: 1.2em;
	  font-weight: 800;
	  letter-spacing: 4px;
	  font-size: 55px;
	  color: var(--color-primary);
	  }
	   .text-overlayod p {
  text-align: left;
  font-size: 19px; /* font-size iki kez belirtilmiş, sadece birini kullanmanız yeterli */
  margin-bottom: 2em;
  justify-content: center;
  line-height: 1.2em;
  letter-spacing: 2px;
  color: #35353b;
  width: 89%;
  word-wrap: break-word;
}
	.vurgun {
		position: absolute;
		top: 8%;
		left: 75px;
		z-index: 5;
		width: 60%;
		text-align: right;
		background: rgba(255,255,255,0);
	
	}
	
	.vurgun:before {
	
		content: '';
		position: absolute;
		width: 85%;  /* Genişlik ayarı */
		height: 75%; /* Yükseklik ayarı */
		top: 130px;       /* Üst pozisyonu ana elemente göre */
		left: 120px;      /* Sol pozisyonu ana elemente göre */
		z-index: -1;  /* Ana elementin altında */
		background: rgba(255,255,255,0.7);
	
		filter: blur(1px);
	}
	.altmeto {
		margin-top: 20px;
		font-size: 20px;
		word-wrap: break-word;
		color: #35353b;
		font-weight: 900;
		filter: brightness(75%)  ;
	
	  }
	  .davxee
	  {
		  font-size: 54px;
	  }
  
  }

  @media (max-width: 768px) {
	.contactholder
{
	width: 100%;
	margin-bottom: 30px;
	padding: 15px;
	
}
  .text-overlayod {
	position: absolute;
	right: 250px;
	top: 0;
	width: 80%; /* veya tercih ettiğiniz genişlik */
	height: 100%;
	background-color: transparent;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 11
	;
  }
.planee{
 	position: absolute;
	right: -70px;
	top: 380px;
 height: 55%;
   background-color: rgba(255,255,255,0.4);

  }
  .ship{
	position: absolute;
   right: -70px;
   top: 45px;
   height: 40%;
   background-color: rgba(255,255,255,0.4);
 }
 .truck{
	position: absolute;
   right: -60px;
   top: 60px;
     height: 44%;
   background-color: rgba(255,255,255,0.4);

 }
  .train{
	position: absolute;
   left: 0px;
   top: 10px;
        height: 55%;
   background-color: rgba(255,255,255,0.4);
 }
	.text-overlayod h2 {
		font-size: 1.5em;
		display: flex;
	justify-content: center;
	  line-height: 1.2em;
	  font-weight: 800;
	  letter-spacing: 4px;
	  font-size: 55px;
	  color: var(--color-primary);
	  }
	   .text-overlayod p {
  text-align: left;
  font-size: 19px; /* font-size iki kez belirtilmiş, sadece birini kullanmanız yeterli */
  margin-bottom: 2em;
  justify-content: center;
  font-weight: 600;
  line-height: 1.2em;
  letter-spacing: 2px;
  color: #35353b;
  width: 89%;
  word-wrap: break-word;
}
	.vurgun {
		position: absolute;
		top: 4%;
		left: 0;
		z-index: 5;
		width: 100%;
		text-align: center;
		background: rgba(255,255,255,0);
		
	
	}
	
	.vurgun:before {
	
		content: '';
		position: absolute;
		width: 200%;  /* Genişlik ayarı */
		height: 75%; /* Yükseklik ayarı */
		top:60px;       /* Üst pozisyonu ana elemente göre */
		left: 0;      /* Sol pozisyonu ana elemente göre */
		z-index: -1;  /* Ana elementin altında */
		background: rgba(255,255,255,0.7);
	
		filter: blur(1px);
	}
	.altmeto {
		margin-top: 20px;
		font-size: 18px;
		word-wrap: break-word;
		color: #35353b;
		font-weight: 900;
		filter: brightness(75%)  ;
		word-wrap: break-word;
	  }
	  .davxee
	  {
		  font-size:35px;
	  }
	  .odinaimgdiv{
		width:100vw;
		height:80vh;
		margin-left: 0;
		z-index: -1;	
		object-fit: cover;
	}
	.mainoddinaaaaa{
		position: relative;
		height: 80vh;
		width: 100vw;
		
	}
	.secondparsh
	{
	  width: 100%;
	  background: linear-gradient(to right, #F0F0F0, #F2F2F2, #F5F5F5, #F8F8F8, #FBFBFB);
	  height: auto;
	  margin-top: 0px;
	}
.bamboimg{
	position: absolute;
	top: 280px;
	left: 0;
	z-index: 0;

}
.text-content {
    padding-right: 20px;
	padding-left: 20px;
    text-align: left;
    width: 100%;
	z-index: 1;
	background-color: rgba(255,255,255,0.7);
}
  }

  @media (max-width: 450px) {

  .slick-dots {
	position: absolute;
    top: 95%;
    right: 0;
    height: 100%;
    display: flex !important;
    flex-direction: row; /* Yatay hizalama için bu satırı ekleyin */
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .innertexth12orc{
	display: flex;
	justify-content: center;
	  line-height: 1.2em;
	  font-weight: 900;
	  letter-spacing: 5px;
	  font-size: 40px;
	  color: var(--color-primary);
	  padding-top: 35px;
	  margin-bottom: 40px;
	  margin-top: 20px;
  
	}

  .text-overlayod {
	position: absolute;
	right: 0;
	top: 0;
	width: 90%; /* veya tercih ettiğiniz genişlik */
	height: 100%;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 11;
  }
.planee{
 	position: absolute;
	left: 0px;
	top: 40px;
 height: 90%;
   background-color: rgba(255,255,255,0.4);

  }
  .ship{
	position: absolute;
   right: -70px;
   top: 45px;
 height: 90%;
   background-color: rgba(255,255,255,0.4);
 }
 .truck{
	position: absolute;
   right: -60px;
   top: 45px;
 height: 90%;
   background-color: rgba(255,255,255,0.4);

 }
  .train{
	position: absolute;
   left: 0px;
   top: 10px;
        height: 55%;
   background-color: rgba(255,255,255,0.4);
      top: 45px;
 height: 90%;
   background-color: rgba(255,255,255,0.4);
 }
	.text-overlayod h2 {
		font-size: 1.5em;
		display: flex;
	justify-content: center;
	  line-height: 1.2em;
	  font-weight: 800;
	  letter-spacing: 4px;
	  font-size: 35px;
	  color: var(--color-primary);
	  }
	   .text-overlayod p {
  text-align: left;
  font-size: 20px; /* font-size iki kez belirtilmiş, sadece birini kullanmanız yeterli */
  margin-bottom: 2em;
  justify-content: center;
  line-height: 1.2em;
  color: #35353b;
  width: 89%;
  word-wrap: break-word;
}
	.vurgun {
		position: absolute;
		top: 4%;
		left: 0;
		z-index: 5;
		width: 100%;
		text-align: center;
		background: rgba(255,255,255,0);
		
	
	}
	
	.vurgun:before {
	
		content: '';
		position: absolute;
		width: 200%;  /* Genişlik ayarı */
		height: 75%; /* Yükseklik ayarı */
		top:60px;       /* Üst pozisyonu ana elemente göre */
		left: 0;      /* Sol pozisyonu ana elemente göre */
		z-index: -1;  /* Ana elementin altında */
		background: rgba(255,255,255,0.7);
	
		filter: blur(1px);
	}
	.altmeto {
		margin-top: 20px;
		font-size: 15px;
		word-wrap: break-word;
		color: #35353b;
		font-weight: 900;
		filter: brightness(75%)  ;
		word-wrap: break-word;
	  }
	  .davxee
	  {
		  font-size:35px;
	  }
	  .odinaimgdiv{
		width:100vw;
		height:80vh;
		margin-left: 0;
		z-index: -1;	
		object-fit: cover;
	}
	.mainoddinaaaaa{
		position: relative;
		height: 80vh;
		width: 100vw;
		
	}
	.secondparsh
	{
	  width: 100%;
	  background: linear-gradient(to right, #F0F0F0, #F2F2F2, #F5F5F5, #F8F8F8, #FBFBFB);
	  height: auto;
	  margin-top: 0px;
	}

.text-content {
    padding-right: 20px;
	padding-left: 20px;
    text-align: left;
    width: 100%;
	z-index: 1;
	background-color: rgba(255,255,255,0.7);
}
  }

  @media (max-width: 450px) 
  {
  .altmeto2 {
  background-color: rgba(254, 99, 7,0.4);
  text-align: center;
	margin-top: 15px;
    font-size: 15px;
    word-wrap: break-word;
    color: #35353b;
	width: 100%;
    font-weight: 900;
    filter: brightness(75%)  ;

	  

  }

  .text-overlayod {
	position: absolute;
	right: 0;
	top: 0;
	width: 90%; /* veya tercih ettiğiniz genişlik */
	height: 100%;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 11;
  }
  .overlay-imagewhite {

	z-index: -10; /* Üst öğeyi zemin öğenin üstüne çıkar */
  }

.planee{
 	position: absolute;
	left: 20%;
	width: 80%;
	top: 30%;
 height: 60%;
   background-color: rgba(255,255,255,0.8);

  }
  .ship{
	position: absolute;
   right: 0;
   top: 10%;
   width: 80%;
 height: 60%;
   background-color: rgba(255,255,255,0.8);

 }
 .truck{
	position: absolute;
   right: 0;
   top: 22%;
   width: 80%;
 height: 60%;
   background-color: rgba(255,255,255,0.8);

 }
  .train{
	position: absolute;
   left: 0;
   top: 10%;
   width: 80%;
 height: 60%;
   background-color: rgba(255,255,255,0.8);
 }
	.text-overlayod h2 {
		font-size: 1.5em;
		display: flex;
	justify-content: center;
	  line-height: 1.2em;
	  font-weight: 800;
	  letter-spacing: 4px;
	  font-size: 31px;
	  color: var(--color-primary);
	  }
	   .text-overlayod p {
  text-align: left;
  font-size: 15px; /* font-size iki kez belirtilmiş, sadece birini kullanmanız yeterli */
  margin-bottom: 2em;
  justify-content: center;
  line-height: 1.2em;
  color: #35353b;
  width: 89%;
  word-wrap: break-word;

}
	.vurgun {
		position: absolute;
		top: 4%;
		left: 0;
		z-index: 5;
		width: 100%;
		text-align: center;
		background: rgba(255,255,255,0);
		
	
	}
	
	.vurgun:before {
	
		content: '';
		position: absolute;
		width: 200%;  /* Genişlik ayarı */
		height: 75%; /* Yükseklik ayarı */
		top:60px;       /* Üst pozisyonu ana elemente göre */
		left: 0;      /* Sol pozisyonu ana elemente göre */
		z-index: -1;  /* Ana elementin altında */
		background: rgba(255,255,255,0.7);
	
		filter: blur(1px);
	}

	  .davxee
	  {
		  font-size:35px;
	  }
	  .odinaimgdiv{
		width:100vw;
		height:80vh;
		margin-left: 0;
		z-index: -1;	
		object-fit: cover;
	}
	.mainoddinaaaaa{
		position: relative;
		height: 80vh;
		width: 100vw;
		
	}
	.secondparsh
	{
	  width: 100%;
	  background: linear-gradient(to right, #F0F0F0, #F2F2F2, #F5F5F5, #F8F8F8, #FBFBFB);
	  height: auto;
	  margin-top: 0px;
	}

.text-content {
    padding-right: 20px;
	padding-left: 20px;
    text-align: left;
    width: 100%;
	z-index: 1;
	background-color: rgba(255,255,255,0.7);
}
  }

  @media(max-width:400px)
  {
	.planee{
		position: absolute;
	   left: 20%;
	   width: 80%;
	   top: 20%;
	height: 80%;
	  background-color: rgba(255,255,255,0.8);
   
	 }
	 .ship{
	   position: absolute;
	  right: 0;
	  top: 10%;
	  width: 80%;
	height: 80%;
	  background-color: rgba(255,255,255,0.8);
   
	}
	.truck{
	   position: absolute;
	  right: 0;
	  top: 20%;
	  width: 80%;
	height: 80%;
	  background-color: rgba(255,255,255,0.8);
   
	}
	 .train{
	   position: absolute;
	  left: 0;
	  top: 10%;
	  width: 80%;
	height: 80%;
	  background-color: rgba(255,255,255,0.8);
	}
	.text-overlayod h2{
		font-size: 28px;
	}
  }