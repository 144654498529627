
@import "src/assets/scss/default/_variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700&display=swap');

.newakefont{
    font-family: 'Microgramma', sans-serif;
    letter-spacing: 2px;
  }
  .dewakefont{
    font-family: 'Red Hat Display', sans-serif;
  }
.sewakefont{
  font-family: 'Orbitron', sans-serif;
}
  @font-face {
    font-family: 'Cemen';
    src: url('../../fonts/cemen-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'bankgothic_md_btmedium';
    src: url('../../fonts/Bank Gothic Light Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Microgramma';
    src: url('../../fonts/microgramma.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Beatrice';
    src: url('../../fonts/Beatrice.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
.stripeimage {
  width: 139px;
  height: 696px;
  position: absolute;
  top: -60%;
  left: 15%;
  z-index: 9999;
  object-fit: cover;
  opacity: 0.6;
  animation: fade 4s ease-in-out infinite;
}
.imgplane{
  width: 1000px;
  height: 1000px;
  object-fit: contain;
  margin-top: -380px;
  margin-left: 600px;


}
.indexes,
.tabs {
  list-style-type: none;



}

.indexes {
  font-size: 2rem; /* İki katına çıkardık */

  & li {
    padding: 2rem; /* İki katına çıkardık */
    border: 2px solid transparent; /* İki katına çıkardık */
    cursor: pointer;
  }
}

.tabs {
  position: relative;
}

.tab {
  position: absolute;
  display: flex;
  width: 1060px; /* İki katına çıkardık */
  height: auto; /* İki katına çıkardık */
  opacity: 0;
  background-color: black;
  overflow: hidden;
}

.moon {
  width: 1200px; /* İki katına çıkardık */
  height: 600px; /* İki katına çıkardık */
  font: 1.4rem impact, sans-serif; /* İki katına çıkardık */
  margin-bottom: 20px;

margin-top: 40px;
  & header {
    font-size: 2.4rem; /* İki katına çıkardık */
    text-transform: uppercase;
    color: white;

    & span {
      color: var(--primary);
    }
  }

  & section {
    display: flex;
    gap: 4rem; /* İki katına çıkardık */
  }
}
.btnnn {
  
  color: #fff;
  cursor: pointer;
  // display: block;
  font-size:16px;
  font-weight: 400;
  line-height: 45px;
  padding: 0 0 2em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;


  
  @media(min-width: 600px) {
      
    padding: 0 1em 2em;
  

  }
  
  &:hover { text-decoration: none; 
  }
  
}



.tab-content svg {
  height: auto; /* SVG'nin yüksekliğinin orantılı olmasını sağlar */
}

.btnnn-1 {
  background:transparent;
  font-weight: 100;
  color: var(--color-body);
  svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0; 
    width: 163px;

  }
  
  rect {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
}

.btnnn-1:hover {
  color: var(--color-body);
  rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
.btnnn-1 a{
  width: auto;
  margin-left: 10px;
}
.btnnn-1 a:hover{
  color: var(--color-body);
}


.tab-content {
  position: relative;
  z-index: 10; /* İki katına çıkardık */
  width: 600px; /* İki katına çıkardık */
  display: flex;
  flex-direction: column;
  gap: 1rem; /* İki katına çıkardık */
  opacity: 0;
  transform: translateY(-10rem); /* İki katına çıkardık */

  & h3 {
    font-weight: 900;
    font-size: 2rem; /* İki katına çıkardık */
    border-bottom: 3px solid white; /* İki katına çıkardık */
    padding-bottom: 2rem; /* İki katına çıkardık */
  }

  & p {
    font-weight: 300;
    line-height: 2; /* İki katına çıkardık */
    color: rgba(255, 255, 255, 0.7);


  }

  & button {
    width: fit-content;
    background-color: transparent;
    color: white;
    border: 2px solid white; /* İki katına çıkardık */
    font-size: 1.4rem; /* İki katına çıkardık */
    padding: 1.5rem 2rem; /* İki katına çıkardık */
    cursor: pointer;
  }
}

@keyframes content {
  100% {
    opacity: 1;
    transform: translateY(0);

  }
}

.tab-image {
  position: absolute;
  right: 2rem; /* İki katına çıkardık */
  width: 400px; /* İki katına çıkardık */
  height: 400px; /* İki katına çıkardık */
  opacity: 0;
  transform: translateX(4rem); /* İki katına çıkardık */

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--overlay);
    mix-blend-mode: multiply;
  }

  & img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    object-position: left;

  }
}

@keyframes image {
  100% {
    opacity: 1;
    width: 600px; /* İki katına çıkardık */
    transform: translateX(0);
  }
}

.active .tab {
  opacity: 1;
  z-index: 10; /* İki katına çıkardık */
}

.active .tab-content {
  animation: content 0.9s ease-out 0.4s forwards;
}

.active .tab-image {
  animation: image 1s ease-out forwards;
}

@keyframes fade {
  0%, 100% {
    opacity: 0.6;
    content: url('http://localhost:3000/images/bg/strip5.png');
  }
  50% {
    opacity: 0.6;
    content: url('http://localhost:3000/images/bg/strip6.png');
  }
}

.barr-container {
  display: flex;
  align-items: center;
  height: 670px;
  position: absolute;
  left: 15%;
  top: -35%;
  z-index: -2;
  opacity: 0.2;
}



/* Ekran genişliği 1600px'in altında olduğunda elementi gizle */
@media (max-width: 1600px) {

}
@media (max-width: 971px) {
  .barr-container {
    display: none;
  }
}
.barr, .barr2 {
  width: 14px;
  height: 670px;
  background: linear-gradient(to bottom, #1158fa 35%, #323190 55%, #364abf, #3766d4, #2a68f7);

}

.barr {
}

.barr2 {
}

@keyframes downup {
  0%, 20%, 100% {
    transform: translateY(-25px);
  }
  40%, 60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(-25px);
  }
}

@keyframes moveUpDown {
  0%, 20%, 100% {
    transform: translateY(0);
  }
  40%, 60% {
    transform: translateY(-25px);
  }
  80% {
    transform: translateY(0);
  }
}


.headingthird{
  font-size: 65px;
  font-weight: 300;
  margin-top: 140px;
}
.unordcontainer {
    width: 100%;

    height: auto;
    margin-top: -8px;
    position: relative;
    background: black;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    padding: 5px;
}
.services-list-container{
    float: right;

}
.services-listtt {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    position: relative;
    padding: 15px;


}


.service-carddd {
    background-color:black;

    text-align: center;
    transition: 0.3s;
    height: 220px;
    width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Kart içeriğini dikeyde ortalamak için kullanıldı */
    align-items: center; /* Kart içeriğini yatayda ortalamak için kullanıldı */
    position: relative; /* Görsel açıdan çapraz hat çizmek için kullanıldı */


}




.service-carddd:hover {
}

.service-titleee {
    font-size: 16px;
    color:var(--color-body);

}

.service-descriptionnn {
    font-size: 12px;
    color: black;

}

.card {
    width: 100%; /* Adjust as needed */
    height: 400px;
    background: transparent;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    display: inline-block;
  }
  
  .card_left,
  .card_middle,
  .card_right {
    height: 100%;
    float: left;
    overflow: hidden;
  }
  
  .card_left {
    width: 30%;
    background: transparent;
  
    &__button {
      width: 100%;
      padding: 20px;
    }
  }
  
  .card_middle {
    width: 35%;
    background: transparent;
    border-color: var(--color-body);
    border: 1px solid;
    border-right: none;


    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      object-position: top;
    }
  }
  
  .card_right {
    width: 35%;
    float: left;
    background: #000000;
    height: 100%;
    border-color: var(--color-body);

    border: 1px solid;


  
    h1 {
      color: var(--color-body);
      font-family: 'Jost', sans-serif;
      font-weight: 600;
      text-align: left;
      font-size: 40px;
      margin: 30px 0 0 0;
      padding: 0 0 0 40px;
      letter-spacing: 1px;
    }
    &__details {
      ul {
        list-style-type: none;
        padding: 0 0 0 40px;
        margin: 10px 0 0 0;
        
        li {
          display: inline;
          color: var(--color-body);
          font-family: 'Jost', sans-serif;
          font-weight: 400;
          font-size: 14px;
          padding: 0 40px 0 0;
        }
      }
    }
  
    &__rating {
      &__stars {
        position: relative;
        right: 160px;
        margin: 10px 0 10px 0;
  
        fieldset, label {
          margin: 0;
          padding: 0;
        }
  
        .rating {
          border: none;
        }
  
        .rating > input {
          display: none;
        }
  
        .rating > label:before {
          margin: 5px;
          font-size: 1.25em;
          display: inline-block;
          content: "\f005";
          font-family: FontAwesome;
        }
  
        .rating > .half:before {
          content: "\f089";
          position: absolute;
        }
  
        .rating > label {
          color: #ddd;
          float: right;
        }
  
        /***** CSS Magic to Highlight Stars on Hover *****/
  
        .rating > input:checked ~ label,
        .rating:not(:checked) > label:hover,
        .rating:not(:checked) > label:hover ~ label {
          color: red;
        }
  
        .rating > input:checked + label:hover,
        .rating > input:checked ~ label:hover,
        .rating > label:hover ~ input:checked ~ label,
        .rating > input:checked ~ label:hover ~ label {
          color: red;
        }
      }
    }
  
    &__review {
      p {
        color: var(--color-body);        
        font-family: 'Jost', sans-serif;
        width: 80%;
        word-wrap: break-word;
        font-size: 13px;
        padding: 0 40px 0 40px;
        letter-spacing: 1px;
        margin: 10px 0 10px 0;
        line-height: 20px;
      }
  
      a {
        text-decoration: none;
        font-family: 'Jost', sans-serif;
        font-size: 14px;
        padding: 0 0 0 40px;
        color: red;
        margin: 0;
      }
    }
  
    &__button {
      a {
        color: red;
        text-decoration: none;
        font-family: 'Montserrat', sans-serif;
        border: 2px solid red;
        padding: 10px 10px 10px 40px;
        font-size: 12px;
        background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/343086/COMdoWZ.png);
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: 7% 50%;
        border-radius: 5px;
        -webkit-transition-property: all;
        transition-property: all;
        -webkit-transition-duration: .5s;
        transition-duration: .5s;
      }
  
      a:hover {
        color: #000000;
        background-color: red;
        background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/343086/rFQ5dHA.png);
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: 10% 50%;
        cursor: pointer;
        -webkit-transition-property: all;
        transition-property: all;
        -webkit-transition-duration: .5s;
        transition-duration: .5s;
      }
  
      padding: 0 0 0 40px;
      margin: 30px 0 0 0;
    }
  }
.bambo{
  text-align: left
}
.spariya{

}
.vixad{

}
.dixad{

}
.padi{

}
.zra{
  display: flex;
  justify-content: flex-end;
}
.gapbeka{
  padding-top: 70px;
}


/* Özel sınıf için kaydırma çubuğunun arka planını (track) özelleştirir */
.infinite-scroll::-webkit-scrollbar-track {
  
background-color: rgba(140,0,0,0.2); /* Çubuğun arka plan rengi */
}
.vertical-bar {

  width: 5px; /* Çubuğun genişliği */
  height: 300px; /* Çubuğun yüksekliği */
  background-color: rgba(140,0,0,0.2); /* Çubuğun arka plan rengi */
  position: relative;
  overflow: hidden; /* Kare çubuğun dışına çıktığında gizlenir */
  margin-right: 50px;
  margin-top: 40px;

}

.moving-square {
  
  width: 20px; /* Karenin genişliği */
  height: 20px; /* Karenin yüksekliği */
  background-color: red; /* Karenin rengi */
  box-shadow: 0 0 15px 5px rgba(255, 0, 0, 0.7); 

  position: absolute;
  bottom: 0; /* Başlangıç pozisyonu */
  animation: moveUpDownn 12s linear infinite; /* Animasyon */
}

@keyframes moveUpDownn {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 280px; /* Çubuğun yüksekliğine göre ayarlayın */
  }
}
.dribba{
  display: flex;
}
.fikar{
  color: red;
  font-size: 28px;
  position: absolute;
  top: -60px;
}

.language-dropdown {
  background-color: black;
  color: white;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  -webkit-appearance: none; /* Tarayıcı özel stillerini kaldırır */
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
}
.rart{

}
.xxxweee{
  width: 550px;
  margin-left: -25px;
}


.tacirmainorta {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../images/bg/blueback2.png) center / cover no-repeat;
  background-attachment: fixed;
  background-position: calc(50% - 50px) center;

}
.tacirmainortatrans{
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../images/bg/blueback2.png) center / cover no-repeat;
  background-attachment: fixed;
  background-position: calc(50% - 50px) center;

}

.palet{
  position: relative;
  width: 75%;
  height: 75%;
  background: url(../../images/bg/paleticon.png) center / contain no-repeat;

}

.ellecleme{
  position: relative;
  width: 80%;
  height: 80%;
  background: url(../../images/bg/ellecicon.png) center / contain no-repeat;

}
.serbestdepo{
  position: relative;
  width: 93%;
  height: 75%;
  background: url(../../images/bg/serbestdepo.png) center / contain no-repeat;

}
.antrepo{
  position: relative;
  width: 75%;
  height: 75%;
  background: url(../../images/bg/antrepo.png) center / contain no-repeat;

}

.denemegps{
  position: relative;
  width: 75%;
  height: 75%;
  background: url(../../images/bg/denemegps.png) center / contain no-repeat;

}
.denemephone
{
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: -5px;
  background: url(../../images/bg/phone.png) center / contain no-repeat;

}
.tacirortayazı{
  position: absolute;
  top: 0%;
  left: 20%;
  background-color: rgba(0,0,0,0.6);
  width: 55%;
  height: 100%;
  z-index: 1;
}
.tacirlerortaici{
  margin-top: 15%;
  padding: 25px;
}
.tacirlerortaother{
  margin-top: 7%;
  padding: 25px;
}
.tacirortah1content{
  color: white;
  font-size: 77px;
}
.tacirortaspancontent

{
  color: rgba(255, 255, 255, 0.6);
  font-size: 26px;
}

.tacirortabuttonstyle{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.tacirortabuttonstylestr{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  position: absolute;
  bottom: 25px;
}

.tacirlercontact-form {
  width: 95%;
  margin: 0 auto;
}

.tacirlerform-group {
  margin-bottom: 15px;
}

.tacirlerform-group label {
  display: block;
  margin-bottom: 5px;
}

.tacirlerform-group input[type="text"],
.tacirlerform-group input[type="email"],
.tacirlerform-group input[type="tel"],
.tacirlerform-group textarea {
  width: 100%;
  padding: 8px;
  background-color: rgba(0,0,0,0.7);
  border-radius: 10;
}

.tacirlerform-group textarea {
  height: 200px;
}
.tacirlerform-group input:focus, .tacirlerform-group textarea:focus {
  border-color: rgba(255,255,255,0.2);
}

.contact-info {
  display: grid;
  width: 100%;
  margin-right: 10px;
  padding-left: 5px;
}

.info-item {
  display: flex;
  align-items: center;
  background-color: rgba(0,0,0,0.7);
  padding: 15px;
}
.info-item i {
  margin-right: 8px; /* İkon ve metin arasında boşluk */
}

.headerpad{
  padding-top: 30px;
}
    @media (max-width: 576px) {
      .dribba{
        display: grid;
      }
      .rart{
          width: 100%;
      }
    .moon {
      display: grid; /* Flex yerine block yapısı kullan */
      grid-template-rows: auto 1fr; /* İndeksler üstte, sekmeler altta olacak şekilde ayarlar */
      width: 100%;
      height: auto;
      margin-top: 0;
  
    }

    .xxxweee{
      width: 430px;
      
    }
    .moon section{
      display: grid; /* Flex yerine block yapısı kullan */
      grid-template-rows: auto 1fr; /* İndeksler üstte, sekmeler altta olacak şekilde ayarlar */
      gap: 0px;
    }
    .tab-content{
      width: 350px;
      height: auto;
    }
    .padi{
      margin-right:5px;
    }
    .zra{
      display: none;
    }
    .vixad{
  width:80% !important; 
    }
    .dixad{
      width:18% !important; 

    }
    
    .spariya{
      margin-left: 16px;
    }
    .active .tab-content{
  
      width: 350px;
      height: auto;
  
    }
    .bambo{
      text-align: center;
    }
    .unordcontainer {
      height: 1000px;
      overflow: hidden;
  
    }
    .tab{
      width: 100%;
      height: auto;
    }
    .active .tab-image img {
      opacity: 0.4;
    }
    .active .tab-content p {
      color:rgba(255, 255, 255, 0.9);
      background: rgba(0,0,0,0.2);
      height: 410px;
      overflow: hidden;
    }
    .tab-image {
      left: 0;
    }
    .sec{
     
    }
    .indexes {
      display: flex;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 20px; 
    }
    .indexes li {
      width: 90px;
    }
  
    .tabs {
      /* Sekmelerin altta sıralanması için ayarlar */
    }
  }
  .basdivstyle{
    display: grid;
    grid-template-columns: 1fr  2fr; /* 3 sütun oluşturur */

  }

  @media (max-width: 1728px) {
    .tacirortayazı{
      left: 19%;
      width: 57%;
    }
  }
  
  @media (max-width: 1584px) {
    .tacirortayazı{
      left: 16%;
      width: 64%;
    }
  }
  
  @media (max-width: 1437px) {
    .tacirortayazı{
      left: 12%;
      width: 72%;
    }
  }
  
  @media (max-width: 1300px) {
    .tacirortayazı{
      left: 7%;
      width: 78%;
    }
  }
  
  @media (max-width: 1168px) {
    .tacirortayazı{
      left: 4%;
      width: 84%;
    }
    .tacirortaspancontent{
      font-size: 24px;
    }
  }
  @media (max-width: 980px) {

    .tacirortaspancontent{
      font-size: 22px;
    }
  }
  
  @media (max-width: 821px) {
    .tacirortayazı{
      left: 0%;
      width: 100%;
    }
    .tacirortaspancontent{
    }
  
  }
  
  @media (max-width: 790px) {
    /* Stiller 790px ve altı için */
  }

  @media (max-width: 640px) {
    .tacirortah1content{
      font-size: 55px;
      
    }
    .tacirortaspancontent{
      font-size: 18px;
    }
    .basdivstyle{
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      gap: 10px;
    }
    
.contact-info {
  display: grid;
  width: 100%;
  margin-right: 10px;
  padding-left: 0px;
}
.info-item{
  width: 100%;
}
  }
  
