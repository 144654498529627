
.logo-dark {
    display: none;
}

.header-default {
    max-width: 100%;
    background-color:var(--color-dark);
    color: #fff;
    font-size: 12px;
    z-index: 99;
    position: relative;
    height: 95px;

    .logo {
        
        a {
            display: flex;
            justify-content: flex-start;
            height: 105px;
            line-height: 105px;
            img {
                height: 120px;
                margin-left: 80px;
                margin-top: -15px;
            }
        }
    }

    .header-right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &.header-relative {
        position: relative;
    }

    .header-right {
        display: flex;
        justify-content: flex-end;
        .header-btn {
            margin-left: 25px;
            @media #{$md-layout} {
                margin-left: 0;
            }
            @media #{$sm-layout} {
                margin-left: 0;
            }
        }
    }

    a,
    button {
        &.btn-default {
            @media #{$sm-layout} {
                height: 33px;
                line-height: 30px;
                padding: 0 9px;
                font-size: 12px;
            }
            &.btn-small {
                @media #{$sm-layout} {
                    height: 33px;
                    line-height: 30px;
                    padding: 0 9px;
                    font-size: 12px;
                }
            }
        }
    }

    &.header-transparent {
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        background: transparent !important;
        backdrop-filter: inherit;
    }
}

.header-transparent-with-topbar {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;

    @media #{$md-layout} {
        position: relative;
    }

    @media #{$sm-layout} {
        position: relative;
    }

    .header-top-bar {
        background-color: transparent;

        @media #{$md-layout} {
            background-color: transparent;
        }

        @media #{$sm-layout} {
            background-color: transparent;
        }
    }

    .header-default {
        background-color: transparent;
        background-image: none;

        @media #{$md-layout} {
            background-color: transparent;
        }

        @media #{$sm-layout} {
            background-color: transparent;
        }
    }
}



.hamberger-button {
    background-color: transparent;
    border: 0 none;
    color: black;
    width: 150px;
    height: 80px;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 15px;
    border-radius: 100%;
    cursor: pointer;
    svg {
        position: relative;
        z-index: 2;
        stroke-width: 2px;
        stroke: black;
    }
}

.header-left-align {
    .mainmenu-nav {
        margin-left: 30px;
    }
}



.rn-header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-dark) !important;
    width: 100%;
    height: 95px;
    animation: headerSticky .95s ease forwards;
    z-index: 9;
}

@keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}


.rawraw{}


@media (min-width: 993px) and (max-width: 1050px) {
    .rawraw{
        margin-left: 100px;
    }
}

@media (max-width: 1050px) {
    .header-default {
        max-width: 100%;
        background-color:var(--color-dark);
        color: #fff;
        font-size: 12px;
        z-index: 99;
        position: relative;
        height: 95px;
    
        .logo {
            
            a {
                display: flex;
                justify-content: flex-start;
                height: 105px;
                line-height: 105px;
                img {
                    height: 120px;
                    margin-left: 20px;
                    margin-top: -15px;
                }
            }
        }
    
        .header-right {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    
        &.header-relative {
            position: relative;
        }
    
        .header-right {
            display: flex;
            justify-content: flex-end;
            .header-btn {
                margin-left: 25px;
                @media #{$md-layout} {
                    margin-left: 0;
                }
                @media #{$sm-layout} {
                    margin-left: 0;
                }
            }
        }
    
        a,
        button {
            &.btn-default {
                @media #{$sm-layout} {
                    height: 33px;
                    line-height: 30px;
                    padding: 0 9px;
                    font-size: 12px;
                }
                &.btn-small {
                    @media #{$sm-layout} {
                        height: 33px;
                        line-height: 30px;
                        padding: 0 9px;
                        font-size: 12px;
                    }
                }
            }
        }
    
        &.header-transparent {
            position: absolute;
            left: 0;
            right: 0;
            width: auto;
            background: transparent !important;
            backdrop-filter: inherit;
        }
    }
    
  }



  @media (max-width: 762px) {
    .hamberger-button {
  
        margin-right: 4px;

    }
  }

 