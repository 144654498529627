/**
 * Typography
 */
 @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
 @import url('https://rsms.me/inter/inter.css');
 @import url('https://fonts.googleapis.com/css2?family=Mohave&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Cherish&family=DM+Serif+Display&display=swap');
* {
	box-sizing: border-box;
}

html {
	overflow: hidden;
	overflow-y: auto;
	margin: 0;
	padding: 0;
}
.trywokr{
	width: 100%;
}
.notnot {
	color: white;
	padding: 10px;
	text-align: center;
	width: 20%;
	height: 50px;
	position: fixed;
	top: 200px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1000;
	border-radius: 10px; 
  }
  .wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 3%;
  }
  .handwrite {
	font-family: 'Mohave', sans-serif;
	font-weight: 400; /* Ağırlık: Normal */
	font-size: 38px; /* Boyut: 16 piksel */
	color: rgba(255, 255, 255, 0.5);
  }
  .user-card {
	 display: block;
	 flex-direction: column;
	 align-items: center;
	 justify-content: center;
	 background-color: #fff;
	 border-radius: 10px;
	 padding: 40px;
	 width: 100%;
	 position: relative;
	 overflow: hidden;
	 box-shadow: 0 2px 20px -5px rgba(0,0,0,0.5);
 }
 .vracontainer{
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-right: auto;
	margin-left: 3%;
}
  .headfont{
	font-family: 'DM Serif Display', serif;
	font-size: 98px;
	font-weight: 400;
  }

@media (max-width: 1600px) {
  .headfont {
    font-size: 82px;
  }
  .handwrite {
    font-size: 28px;
  }
}

@media (max-width: 1366px) {
  .headfont {
    font-size: 64px;
  }
  .handwrite {
    font-size: 24px;
  }
}

@media (max-width: 1024px) {
  .headfont {
    font-size: 55px;
  }
  .handwrite {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .headfont {
    font-size: 55px;
  }
  .handwrite {
    font-size: 28px;
  }
  .vracontainer{
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-right: auto;
	margin-left: 3%;
	margin-top: -40%;
}
.btr{
	width: 40%;
}

}

.btr{
	padding: 0 28px;
	border-style: solid;
	border-width: 2px;
	border-color: transparent;
	border-radius: 8px;
	background-color: transparent;
	background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95% / 200% 100%;
	transition-duration: 300ms;
	color: var(--color-white);
	font-size: 16px;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	text-shadow: var(--shadow-light);
	display: inline-block;
	height: 50px;
	line-height: 47px;
	letter-spacing: 0.5px;

	&:hover {
		box-shadow: var(--shadow-primary);
		-webkit-filter: brightness(125%);
		filter: brightness(125%);
		color: var(--color-white);
		text-decoration: none;
	}
}



@media (max-width: 412px) {
	.headfont {
	  font-size: 45px;
	}
	.handwrite {
	  font-size: 26px;
	}
	.btr{
		width: 40%;
	}
  }
 .user-card:before {
	content: '';
	position: absolute;
	height: 90%;
	width: 173px;
	background: #44a4f4;
	top:20px;
	right: -109px;
	z-index: 0;
	transform: rotate(-17deg);
  }
  

.trybutton{
	width: 10%;
}
.divpad{
	padding-top: 20px;
	text-align: left;

}
  
  .user-card-info {
	text-align: left;
  }
  
  .user-card-info h2 {
	color: rgba(0,0,0,0.5);
	font-size: 24px;
	margin: 0;
	margin-bottom: 10px;
	font-family: 'Bebas Neue', sans-serif;
  }

.boxe {
	box-shadow: 0px 5px 5px rgba(0, 0, 0, 1);
	width: 100%;
	z-index: 9999;

}

  
  .videoo {
	position: relative;
	width: 100%;
	height: 100%;
  }
  

  
  
  .user-card-info p {
	font-size: 14px;
	margin-bottom: 2px;
	font-size: calc(.6rem + .4vw);
  }
  .user-card-info p span {
	 font-weight: 700;
	 margin-right: 10px;
 }
  @media only screen and (min-width: 768px) {
	.user-card {
	  flex-direction: row;
	  align-items: flex-start;
	}   
	
  
	.user-card-info {
	  text-align: left;
	}
  }
 
  @media (max-width: 767px){
	.trybutton{
		width: 100%;
	}
	.wrapper{
	   padding-top: 3%;
	}
	.user-card:before {
	   width: 40%;
	   height: 200px;
	   transform: rotate(0);
	}
	.user-card-info h2 {
	   margin-top: 25px;
	   font-size: 35px;
	}
	.user-card-info p span {
	   display: block;
	   margin-bottom: 15px;
	   font-size: 18px;
	}
  }
  .background-video {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  object-fit: cover;

}

  .newwrack{

  }
  
   
  @media (max-width: 980px) {
	.newwrack {
		flex-direction: column;
		width: 100%;
	}
}

  @media (max-width: 1400px) {
	.notnot {
	  width: 35%;
	}
	
  }

  @media (max-width: 1200px) {
	.notnot {
	  width: 40%;
	}
	
  }
  @media (max-width: 768px) {
	.notnot {
	  width: 80%;
	}
	
  }
  .ck-content h1, .ck-content h2, .ck-content h3 {
	color: black; /* İstediğiniz rengi seçin */
  }
.innerbreaker{
	width: 390px;
	height: 240px;
	object-fit: cover;

}
.innercrack{
	width: 100%;
	height: 500px;
	object-fit: cover;

}
.wibrax{
	width:100%;
    word-wrap: break-word;
}
.xddw {
	/* Diğer stil özellikleri */
  }
  
  .xddw:hover {
	color: #059DFF;
  }
body {
	overflow: hidden;
	font-size: var(--font-size-b2);;
	line-height: var(--line-height-b2);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--font-primary);
	color: var(--color-body);
	font-weight: var(--p-regular);
	background-color: var(--color-dark);
	margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
	margin: 0;
	margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	word-break: break-word;
	font-family: var(--secondary-font);
	line-height: 1.4074;
	color: var(--color-heading);
}

h1,
.h1 {
	font-size: var(--h1);
	line-height: 1.19;
}

h2,
.h2 {
	font-size: var(--h2);
	line-height: 1.23;
}

h3,
.h3 {
	font-size: var(--h3);
	line-height: 1.4;
}

h4,
.h4 {
	font-size: var(--h4);
	line-height: 1.25;
}

h5,
.h5 {
	font-size: var(--h5);
	line-height: 1.24;
}

h6,
.h6 {
	font-size: var(--h6);
	line-height: 1.25;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	a {
		color: inherit;
		transition: 0.5s;
	}
}

a {
	color: var(--color-body);
}
@media #{$md-layout} {
	h1,
	.h1 {
		font-size: 38px;
	}
	h2,
	.h2 {
		font-size: 32px;
	}

	h3,
	.h3 {
		font-size: 28px;
	}

	h4,
	.h4 {
		font-size: 24px;
	}

	h5,
	.h5 {
		font-size: 18px;
	}
}


@media #{$sm-layout} {
	h1,
	.h1 {
		font-size: 34px;
	}

	h2,
	.h2 {
		font-size: 28px;
	}

	h3,
	.h3 {
		font-size: 24px;
	}

	h4,
	.h4 {
		font-size: 20px;
	}

	h5,
	.h5 {
		font-size: 16px;
	}

}


h1,
.h1,
h2,
.h2,
h3,
.h3 {
	font-weight: var(--p-bold);
}

h4,
.h4,
h5,
.h5 {
	font-weight: var(--p-bold);
}

h6,
.h6 {
	font-weight: var(--p-medium);
}

p {
	font-size: var(--font-size-b2);;
	line-height: var(--line-height-b2);
	font-weight: var(--p-regular);
	color: var(--color-body);
	margin: 0 0 30px;

	@media #{$sm-layout} {
		margin: 0 0 20px;
		font-size: var(--font-size-b2);
		line-height: var(--line-height-b2);
	}

	&.has-large-font-size {
		line-height: 1.5;
		font-size: 36px;
	}

	&.has-medium-font-size {
		font-size: 24px;
		line-height: 36px;
	}

	&.has-small-font-size {
		font-size: 13px;
	}

	&.has-very-light-gray-color {
		color: var(--color-white);
	}

	&.has-background {
		padding: 20px 30px;
	}

	&.b1 {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);
	}

	&.b2 {
		font-size: var(--font-size-b2);
		line-height: var(--line-height-b2);
	}

	&.b3 {
		font-size: var(--font-size-b3);
		line-height: var(--line-height-b3);
	}

	&.b4 {
		font-size: var(--font-size-b4);
		line-height: var(--line-height-b4);
	}

	
}

.b1 {
	font-size: var(--font-size-b1);
	line-height: var(--line-height-b1);
}

.b2 {
	font-size: var(--font-size-b2);
	line-height: var(--line-height-b2);
}

.b3 {
	font-size: var(--font-size-b3);
	line-height: var(--line-height-b3);
}

.b4 {
	font-size: var(--font-size-b4);
	line-height: var(--line-height-b4);
}


table {
	border-collapse: collapse;
	border-spacing: 0;
	margin: 0 0 40px;
	width: 100%;
}

table a,
table a:link,
table a:visited {
	text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
	color: var(--color-heading);
}



var {
	font-family: "Courier 10 Pitch", Courier, monospace;
}

/*---------------------------
	List Style 
---------------------------*/
ul,
ol {
	padding-left: 18px;
}

ul {
	list-style: square;
	margin-bottom: 30px;
	padding-left: 20px;

	&.liststyle {
		&.bullet {
			li {
				font-size: 24px;
				line-height: 30px;
				color: var(--color-body);
				position: relative;
				padding-left: 30px;

				@media #{$sm-layout} {
					padding-left: 19px;
				}

				&::before {
					position: absolute;
					content: "";
					width: 6px;
					height: 6px;
					border-radius: 100%;
					background: var(--color-body);
					left: 0;
					top: 10px;
				}

				&+li {
					margin-top: 8px;
				}
			}
		}
	}

	li {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);
		margin-top: 10px;
		margin-bottom: 10px;
		color: var(--color-body);
		font-weight: 400;

		a {
			text-decoration: none;
			color: var(--color-body);
			@extend %transition;

			&:hover {
				color: var(--color-body);
			}
		}
	}

	ul {
		margin-bottom: 0;
	}
}

ol {
	margin-bottom: 30px;
	li {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);
		color: var(--color-gray);
		margin-top: 10px;
		margin-bottom: 10px;
		font-weight: 400;
		a {
			color: var(--heading-color);
			@extend %transition;
			text-decoration: none;
			&:hover {
				color: var(--color-body);
			}
		}
	}
	ul {
		padding-left: 30px;
	}
}
hr {
    display: block;
    height: 0;
    margin: 15px 0 15px;
    border-top: 1px solid var(--color-border);
}
kbd {
    background: var(--color-body);
}








